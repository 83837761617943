.edit-new-client form ul li.field > div:first-child
{
    width: 220px;
}

.create-new-client form ul li.field > div:first-child {
    width: 200px;
}
.create-new-client form ul > li.field > div:last-child {
    width: 260px;
}

.edit-new-client h2 {
    margin: 5px 0 25px 40px;
}

.deposit-funds h2 {
    margin: 5px 0 20px 20px;
}
.deposit-funds form ul > li.field > div:first-child {
    width: 190px;
    padding-left: 0px !important;
}
.deposit-funds form ul > li.field > div:last-child {
    width: 200px;
}
.deposit-funds form ul > li.submit {
    margin-top: 20px !important;
}

.manage-games form.thin {
    padding: 15px 4px 25px;
}
.manage-games form.thin ul > li.field > div:first-child {
    width: 160px;
}
.manage-games form.thin ul > li.field > div:last-child {
    width: 370px;
}
.manage-games form.thin ul > li.inCol > div.inRow {
    width: 270px;
    justify-content: start;
}

.admin {
    color: #ece498 !important;
}


.listSmallButton {
    display: inline-block;
    position: relative;
    background: none;
    width: 14px;
    height: 9px;
    padding: 0;
    border: none;
}
.listSmallButton > .small2Icon {
    position: relative;
    vertical-align: baseline;
    opacity: 0.5;
    left: 5px;
}
.listSmallButton:hover > .small2Icon {
    opacity: 1;
}

.gameReleaseStatus {

}
.gameReleaseStatus > div {
    display: inline-block;
    width: 110px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid;
    opacity: 0.5;
    background-color: #a9bdce;
    margin: 4px 6px;
    font-size: 11px;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
    padding: 0 4px;
}
.gameReleaseStatus > div.active {
    cursor: default;
    border-width: 3px;
    font-weight: bold;
    opacity: 1;
}

.gameReleaseStatus > div.grs-nr {
    background-color: #b7c8ec;
    border-color: #7b7baf;
}
.gameReleaseStatus > div.grs-normal {
    background-color: #63e872;
    border-color: #3d8e46;
}
.gameReleaseStatus > div.grs-fb99 {
    background-color: #e8c263;
    border-color: #786433;
}
.gameReleaseStatus > div.grs-fbban {
    background-color: #cecece;
    border-color: #5e5e5e;
}
.gameReleaseStatus > div.grs-ban {
    background-color: #e86363;
    border-color: #783333;
}