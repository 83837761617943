body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #dad6d5 /*#f0f7fb*/;
}

h1 {
    font: 28pt Verdana;
}

a.soft {
    text-decoration: none;
    color:inherit;
}
a.soft:hover,
a.soft:active
{
    text-decoration: none;
    border-bottom: 1px dashed #b3762c;
}

a.light {
    text-decoration: none;
    color: #e25a00;
}
a.light:hover,
a.light:active
{
    text-decoration: none;
    border-bottom: 1px dashed #b3622c;
}

.page {
    font: 16px Tahoma;
    color: #3e342d;
    text-align: center;
    padding: 15px 10px 40px;
}

.page .section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5px;
}
.page .footer, .page .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.logo2 {
    margin: 30px;
}

.smallerText {
    font-size: 14px;
}
.smallText {
    font-size: 12px;
}
.verySmallText {
    font-size: 10px;
}
.veryVerySmallText {
    font-size: 8px;
}
.bold {
    font-weight: bold;
}

.disabled {
    color: #999999;
}
.optional {
    color: #ab9f8d;
}
.separateData {
    color: #935b2b;
}

.inRow {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
}
.inRow > * {
    margin-right: 5px;
    margin-left: 5px;
}
.inCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.rightAlign {
    text-align: right;
    justify-content: flex-end;
}
.leftAlign {
    text-align: left;
    justify-content: flex-start;
}

.noselect, label {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.tip {
    font-size: 12px;
    font-style: italic;
}
.tip p {
    margin: 0;
}
.caption {
    font-size: 13px;
    font-style: italic;
    margin-bottom: 5px;
}

.field {
    margin: 18px 0px;
}
.shortField {
    width: 100px;
}

.readOnlyFrame {
    background: #eae4e2;
    padding: 8px;
    border-radius: 5px;
    border: #cbab99 1px solid;
}


.tokenFrame {
    width: auto;
    display: inline;
    margin: 13px 5px;
    padding: 9px 12px 5px 6px;
    font-family: 'Courier New', Arial, sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: #2b2e32;
}
.tokenFrame button {
    position: relative;
    top: -5px;
}


.readOnlyCode {
    background: #eae4e2;
    padding: 8px;
    border-radius: 5px;
    border: #cbb099 1px solid;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.smallList {
    background: #eae5df;
    padding: 8px;
    border-radius: 5px;
    border: #cbaf99 1px solid;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
}

.smallItem {
    padding: 5px 8px;
    border-radius: 4px;
    border: #decfc1 1px solid;
    background: #f5f1ec;
    font-size: 12px;
    margin: 3px 3px;
}

.tinyItem {
    padding: 3px;
    border-radius: 4px;
    border: #dcd0bf 1px solid;
    background: #f5efec;
    font-size: 9px;
    margin: 2px 2px;
}
button.tinyItem:hover {
    background: #fcf6f3;
}

.__react_component_tooltip {
    line-height: normal;
    font-family: Tahoma;
}
.__react_component_tooltip.type-light {
    font-size: 11px;
    padding: 2px 6px 4px;
    font-weight: normal;
    transition: opacity 0.3s ease-out;
    background-color: #bc9377 !important;
    color: white !important;
    margin-top: -4px !important;
    opacity: 0.75;
}
.__react_component_tooltip.type-light.place-top:before{
    border-top:8px solid #bc8f77 !important;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    margin-left: -8px;
    bottom: -4px;
}
.__react_component_tooltip.type-light.place-top:after {
    border-top-color: #bc9377 !important;
    border-top-width: 4px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: -6px;
    bottom: -2px;
}

.__react_component_tooltip.type-dark {
    font-size: 11px;
    padding: 2px 6px 4px;
    font-weight: normal;
    transition: opacity 0.3s ease-out;
    background-color: #95642f;
    color: white;
    margin-top: -4px;
    opacity: 0.95;
}
.__react_component_tooltip.type-dark.place-top:before{
    border-top:8px solid #955b2f;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    margin-left: -8px;
    bottom: -4px;
}
.__react_component_tooltip.type-dark.place-top:after {
    border-top-color: #95532f;
    border-top-width: 4px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    margin-left: -6px;
    bottom: -2px;
}

i.arrow {
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    top: 4px;
}
.arrow.right {
    background: url(/icons/arrow-right2.png) center center no-repeat;
}
.arrow.down {
    background: url(/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.arrow.left {
    background: url(/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.arrow.up {
    background: url(/icons/arrow-right2.png) center center no-repeat;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}
.emoji {
    background: url(../img/emoji.png) center center no-repeat;
}

.floating {
    position: relative;
}
button.floating {
    padding: 0px;
}

button.arrow:hover { filter: brightness(1.05); }
button.arrow:active { filter: brightness(0.95); }
button.arrow:hover { filter: brightness(1.05); }
button.arrow:active { filter: brightness(0.95); }
button.arrow:hover { filter: brightness(1.05); }
button.arrow:active { filter: brightness(0.95); }
button.arrow:hover { filter: brightness(1.05); }
button.arrow:active { filter: brightness(0.95); }


button.text-and-icon *:not(.icon):first-child {
    margin-right: -8px;
}
button.text-and-icon .icon:last-child {
    margin-left: 8px;
}
button.text-and-icon *:not(.icon):last-child {
    margin-left: -8px;
}
button.text-and-icon .icon:first-child {
    margin-right: 8px;
}

button.text-and-icon .icon {
    display: inline-block;
    width: 20px;
    height: 0;
    position: relative;
    top: -5px;
}

.page input[type=text], .page input[type=password] {
    border: 1px solid #7a553d;
    border-radius: 4px;
    padding: 6px;
    font-size: 16px;
    cursor: text;
}
.page input[type=text]::placeholder {
    color: #a1a8aa;
    font-style: italic;
    font-size: 14px;
}
.page input[type=text].smallText {
    font-size: 14px;
}
textarea {
    cursor: text;
}

.page input[type=checkbox] {
    margin-right: 10px;
    cursor: pointer;
}
.checkbox {
    cursor: pointer;
}
.page li.checkbox, div.checkbox {
    display: flex;
    flex-direction: row !important;
}
.page div.checkbox label.smallerText {
    margin-top: 1px;
}

.page select {
    border: 1px solid #7a503d;
    border-radius: 4px;
    padding: 6px;
    font-size: 16px;
}
.page select.smallerText {
    padding: 3px;
}
/*.select.smallText {
    height: 28px;
}*/

.widget {
    all:initial;
}

.page button {
    border: none;
    cursor: pointer;
    display: inline-block;
    outline: 0;
}
button.common, button.secondary, button.dev-test {
    background-color: #a99187;
    font-size: 16px;
    color: #ffffff;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
button.common:not(.small), button.secondary:not(.small), button.dev-test:not(.small) {
    padding: 14px 28px;
}
.page a {
    text-decoration: none;
}
button.common:hover {background-color: #bd9387;}
button.common:disabled {background-color: #a09895;color: #bebebe;}

button.secondary {
    background-color: #e3d1c9;
    border: 1px solid #c99990;
    color: #835244;
}
button.secondary:hover {background-color: #d5bbae;}
button.secondary .icon {opacity: 0.7}
button.secondary:hover .icon {opacity: 0.9}

button.green {
    background-color: #5caf5e; /*  #55af55 */
    padding: 0px 14px 0 9px;
    font-size: 16px;
    color: #ffffff;
    border-radius: 3px;
    height: 46px;
}
button.green:hover {background-color: #509852;}
button.green .icon {
    opacity: 0.9;
}

button.small2 {
    padding: 8px 12px !important;
    font-size: 12px !important;
}

.page form.simple {
    max-width: 480px;
    background: none;
    padding: 15px 15px 20px;
    margin: 5px 0px;
    border-radius: 0px;
    border: none;
}

.page form.thin {
    max-width: 480px;
    padding: 15px 15px 25px;
    margin: 20px auto;
    border-radius: 6px;
    border: 1px solid #7a503d;
}

.page form {
    max-width: 480px;
    background: #eae4e2;
    padding: 30px 15px;
    margin: 20px auto;
    border-radius: 6px;
    border: 2px solid #bba097;
}
.page form > ul {
    padding:0;
    margin:0;
    list-style:none;
}
.page form > ul > li {
    margin-bottom: 25px;
    min-height: 35px;
}
.page form > ul > li .field-style {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    padding: 8px;
    outline: none;
    border: 1px solid #e0bfb0;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;

}
.page form > ul > li  .field-style:focus{
     box-shadow: 0 0 5px #B0CFE0;
     border:1px solid #B0CFE0;
}
.page form > ul > li .field-split{
    width: 49%;
}
.page form > ul > li .field-full{
    width: 100%;
}
.page form > ul > li input.align-left{
    float:left;
}
.page form > ul > li input.align-right{
    float:right;
}
.page form > ul > li textarea {
    height: 40px;
    border: 1px solid #7a553d;
    border-radius: 4px;
    padding: 6px;
    font-size: 14px
}
.page form > ul > li input[type="button"],
.page form > ul > li input[type="submit"] {
    -moz-box-shadow: inset 0px 1px 0px 0px #b17139;
    -webkit-box-shadow: inset 0px 1px 0px 0px #b16339;
    box-shadow: inset 0px 1px 0px 0px #b16939;
    background-color: #884a21;
    border: 1px solid #5e3317;
    display: inline-block;
    cursor: pointer;
    color: #FFFFFF;
    padding: 8px 18px;
    text-decoration: none;
    font: 12px Arial, Helvetica, sans-serif;
}
.page form > ul > li input[type="button"]:hover,
.page form > ul > li input[type="submit"]:hover {
    background: linear-gradient(to bottom, #a2562d 5%, #a86233 100%);
    background-color: #9e5728;
}


.page form > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    /*flex-direction: row;*/
}
.page form > ul > li:not(.checkbox) > div {
    display: flex;
    flex-direction: column;
}
.page form > ul > li > div > div:not([class]) {
     margin: 0 10px;
 }

.page form > ul > li.field > div:first-child {
    text-align: right;
    padding: 0 10px 0 20px;
    width: 80px;
}

.page form > ul > li.field > div:last-child {
    width: 220px;
    text-align: left;
    padding: 0 30px 0 10px;
}

.page form.simple > ul > li.field > div:first-child {
    width: 120px;
}

.page form > ul > li.submit {
    margin-bottom: 5px;
}
.page form > ul > li.submit > div {
    width: auto;
}

.medium {
    width: 40%;
}
textarea.medium {
    height: 100px;
}

.holder.wide {
    width: 100%;
}
textarea.wide {
    width: 100%;
    height: 80px;
    box-sizing: border-box;
}
.page form.wide {
    max-width: none;
    width: 600px;
}
.page form.wide > ul > li.field, form.wide > * > ul > li.field {
    display: flex;
    flex-direction: column;
}
.page form.wide > ul > li.field > div, form.wide > * > ul > li.field > div {
    margin: 2px 30px;
    text-align: left;
    width: 600px;
    padding: 0;
}
.page form .required {
    display: inline-block;
    color: #e40300;
    padding: 0 3px;
}
.page form .required::after {
    content: '*';
}

.displayErrors input:invalid {
    border-color: #ce1f0f;
}

.holder {
    width: 0;
    height: 0;
    overflow: visible;
    margin: 0 !important;
}

.error {
    position: relative;
    color: #ce1f0f;
    font-size: 10px;
    top: 0;
    width: 100%;
    text-align: center;
}
.field .error {
    left: 8px;
}


.tinyTip {
    position: relative;
    color: #e46327;
    font-size: 10px;
    top: 5px;
    width: 100%;
    text-align: center;
}

/* main menu */

.mainmenu {
    overflow: hidden;
    background-color: #171717 /*#2e353f*/;
    font-family: Verdana, sans-serif;
}

.mainmenu a {
    float: left;
    color: #f2f2f2;
    font-size: 17px;
    text-decoration: none;
    text-align: center;
}

.mainmenu a.right {
    float: right;
}

.mainmenu-logo > div {
    background-image: url(/logo_small.png);
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 256px;
    height: 50px;
    padding: 0px 15px 4px;
    margin-right: 40px;
}

.mainmenu-list {
    padding: 0 30px 0 10px;
}

.mainmenu-list a {
    padding: 17px 16px;
    min-width: 70px;
}

.mainmenu-list a:hover {
    background-color: #705441;
    color: white;
}
.mainmenu-list a.active {
    background-color: #815f49;
    color: white;
}

/* menu */

/* main menu */

.menu {
    overflow: hidden;
    font-family: Helvetica, sans-serif;
    margin: 20px 20px;
}

.menu a {
    color: #f2f2f2;
    font-size: 17px;
    text-decoration: none;
    text-align: left;
    display: block;
}

.menu-list {
    width: 270px;
    padding: 0;
    background-color: #171717;
}

.menu-list a {
    padding: 5px 15px;
    height: 48px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.menu-list a:hover {
    background-color: #705441;
    color: white;
}
.menu-list a.active {
    background-color: #815f49;
    color: white;
}

.menu-list a .icon {
    margin: 10px 10px 10px 0;
    top: 0;
    flex: 0 0 20px;
}

.topstats {
    float: right;
    display: flex;
    flex-direction: column;
    min-width: 120px;
    color: white;
    padding: 2px 20px;
    margin: 0 20px;
    background-color: #77ab47;
    height: 50px;
    cursor: default;
    font-size: 14px;
}
.topstats.negative {

}
.topstats > div {
    padding: 5px 0 0px;
}
.topstats .balance {
    font-size: 12px;
    font-style: italic;
}
.topstats .balance .sum {
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    /*text-shadow: rgba(69, 178, 73, 0.7) 0 0 2px, rgba(78, 165, 100, 0.7) 0 0 1px;*/
    color: rgb(242, 255, 232);
    text-shadow: rgba(5, 21, 8, 0.5) 0 0 2px, rgba(9, 33, 11, 0.5) 0 0 1px;
}
.balance .sum.negative {
    color: rgb(255, 205, 196);
    text-shadow: rgba(199, 43, 24, 0.7) 0 0 2px, rgba(169, 42, 32, 0.8) 0 0 1px;

}
.blue {
    color: rgb(65, 164, 255);
}
.green {
    color: rgb(52, 177, 66);
}
.weakgreen {
    color: rgb(147, 110, 0);
}
.gray {
    color: #a1a1a1 !important;
}
.red {
    color: #e40300 !important;
}
.yellow {
    color: #c18f00 !important;
}
.text-default {
    color: #3e342d !important;;
}
.strikethrough  {
    text-decoration: line-through;
}

.text-blue {
    color: #8d7b47;
}
.text-gray {
    color: #cbc2bb;
}

.topstats .username {
    font-weight: bold
}

.warnTopPanel {
    border: 1px solid #a8a06b;
    background-color: #ffc761;
    height: 20px;
    font-size: 13px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #302600;
    box-sizing: border-box;
}
.warnTopPanel.strong {
    border: 1px solid #950f11;
    background-color: #ff5549;
    color: #400b07;
    font-weight: bold;
}
.warnTopPanelHolder {
    height: 0;
    width: 100%;
    overflow: visible;
}

.right-menu-page {
    display: flex;
    margin: 0 8%;
}

.right-menu-page > div:first-child {
    flex: 20%;
    min-width: 270px;
}
.right-menu-page > div:last-child {
    flex: 70%;
}

button.copy-to-clipboard {
    background: url(/icons/copy2.png) center center no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    top: 4px;
    margin: 0 4px 0 2px;
}
button.copy-to-clipboard:hover {
    filter: brightness(1.05);
}
button.copy-to-clipboard:active {
    filter: brightness(0.95);
}

button.edit {
    background: url(/icons/edit2.png) center center no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}
button.edit:hover {
    filter: brightness(1.05);
}
button.edit:active {
    filter: brightness(0.95);
}


.pushIcon {
    background: url(../img/push.png) center center no-repeat;
}
.pushPressedIcon {
    background: url(../img/push_p.png) center center no-repeat;
}
.pushPressedIcon:after, .pushIcon:after {
    content: '';
    position: absolute;
    top:-2px; bottom:-2px; left:-2px; right:-2px;
}

.activeIcon {
    background: url(../img/active.png) center center no-repeat;
}
.inactiveIcon {
    background: url(../img/inactive.png) center center no-repeat;
}

.checkIcon {
    background: url(../img/check.png) center center no-repeat;
    position: relative;
    top:5px;
 }

.pages {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.pages > * {
    min-width: 36px;
    margin: 4px 5px;
}
.pages button {
    min-height: 38px;
}
.pages .prev button {
    /*padding: 4px 8px 8px 0;*/
    padding: 0 8px 6px 8px;
}
.pages .next button {
    /*padding: 4px 0 8px 8px;*/
    padding: 0 8px 6px 8px;
}
.pages .current {
    min-width: 40px;
    padding: 8px 0;
    border-radius: 3px;
    background-color: #e7ddd9;
}

.page .form {
    width: 500px;
    margin: 0 auto;
    text-align: left;
}
.page .wide-form {
    width: 100%;
    min-width: 800px;
    margin: 0 auto;
    text-align: left;
}

.page .center-section {
    width: 400px;
    margin: 0 auto;
}
.page .left-align-section {
    width: 400px;
    margin: 0 0;
    text-align: left;
}

.page h2 {
    font-size: 16px;
}
.page > h2 {
    margin-top: 30px;
}
.page h3 {
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
    margin: 20px 0 10px 20px;
}

.page ul.points {
    list-style-type: circle;
}
.page ul {
    list-style:none;
}
.page > ul > li, .page > * > ul > li {
    margin: 6px 10px;
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.page > ul > li.smaller, .page > * > ul > li.smaller {
    margin: 3px 10px 2px;
}
.page > ul > li > div, .page > * > ul > li > div {
    margin: 0 5px;
}
.page > ul.info, .page > * > ul.info {
    width: 80%;
}

.page > div.info, .page > * > div.info {
    margin: 0 auto;
    width: 80%;
    text-align: left;
}
div.info > p {
    line-height: 1.5;
}

.page button {
    cursor: pointer;
    outline: 0;
}
.page button > span {
    cursor: pointer !important;
}
.page button.icon, div.icon, span.icon {
    display: inline-block;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
}
button.common.icon {
    min-width: 40px;
}
span.icon {
    vertical-align: bottom;
}


/*    top: 4px;
    margin: 0 6px 0 2px;

 */


.page button.dev-test {background-color: #62af7f;}
.page button.dev-test:hover {background-color: #559671;}
.page button.dev-test:disabled {background-color: #739682;color: #a7beb2;}


.small-spinner {
    width: 12px;
    height: 8px;
    display: inline-block;
    vertical-align: baseline;
    overflow: visible;
}
.small-spinner > div {
    position: relative;
    background: url(../img/small-spinner.svg) center center no-repeat;
    width: 12px;
    height: 10px;
    top: -1px;
    left: 1px;
}

/* icons */
.upIcon {
    background: url(../img/up_icon.png) center center no-repeat;
    width: 10px; height: 9px; display: inline-block; vertical-align: baseline; margin: 0px 1px 0px 0px;
}
.up2Icon {
    background: url(../img/up2_icon.png) center center no-repeat;
    width: 10px; height: 9px; display: inline-block; vertical-align: baseline; margin: 0px 1px 0px 0px;
}
.downIcon {
    background: url(../img/down_icon.png) center center no-repeat;
    width: 10px; height: 9px; display: inline-block; vertical-align: baseline; margin: 0px 1px 0px 0px;
}
.down2Icon {
    background: url(../img/down2_icon.png) center center no-repeat;
    width: 10px; height: 9px; display: inline-block; vertical-align: baseline; margin: 0px 1px 0px 0px;
}
.stableIcon {
    background: url(../img/stable_icon.png) center center no-repeat;
    width: 7px; height: 9px; display: inline-block; vertical-align: baseline; margin: 0px 1px 0px 0px;
}
.emptyIcon {
    width: 5px; height: 9px; display: inline-block; vertical-align: baseline;
}

.btnLoadIcon {
    background: url(../img/btn_load_ico.gif) center center no-repeat;
    width: 16px; height: 11px; display: inline-block; vertical-align: baseline;
    margin: 4px 32px 3px;
}

.loading {
    background: url(../img/loading.gif) center center no-repeat;
    width: 50px; height: 16px; display: inline-block; vertical-align: baseline;
    margin: 4px 16px 3px;
}
.long-loading {
    background: url(../img/long-loading.gif) center center no-repeat;
    width: 220px; height: 19px; display: inline-block; vertical-align: baseline;
    margin: 4px 32px 3px;
}

.repeatSmallIcon {
    background: url(../img/repeat_small.png) center center no-repeat;
    width: 8px; height: 8px;
}
.crossSmallIcon {
    background: url(../img/cross_small.png) center center no-repeat;
    width: 8px; height: 8px;
}
.crossSmall2Icon {
    background: url(../img/cross_small2.png) center center no-repeat;
    width: 10px; height: 10px;
}



button.audio {
    display: inline-block;
    position: relative;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    border: none;
    outline: 0;
    width: 26px;
    height: 26px;
    vertical-align: baseline;
    margin: 4px;
}
button.audio:hover {
    filter: brightness(1.1);
}
button.audio:active {
    filter: brightness(0.95);
}
button.audio.pause {
    background: url(../img/audio_pause.png) center center no-repeat;
}
button.audio.play {
    background: url(../img/audio_play.png) center center no-repeat;
}

li.react-datepicker__time-list-item {
    height: 16px !important;
}
button.react-datepicker__navigation--next {
    padding-right: 14px !important;
}
.react-datepicker-time__header {
    content: 'fsdf';
}

.emoji-mart {
    font-size: 14px !important;
}
.emoji-mart-title-label {
    font-size: 16px !important;
}
section.emoji-mart {
    border: 1px solid #716153;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid rgba(118, 104, 87, 0.3);
    box-shadow: 1px 1px 4px rgba(106, 90, 76, 0.6);
}
.emoji-mart-scroll {
    height: 215px !important;
}

@font-face {
    font-family: 'NotoColorEmoji';
    src: url('/fonts/NotoColorEmoji.ttf') format('truetype');
}

.emoji-text {
    font-family: Tahoma, NotoColorEmoji, serif;
}

.separator {
    flex-basis: 100%;
    height: 0;
}


.smallTable {
    background: #eae4e2;
    border-radius: 5px;
    border: #c4baa8 1px solid;
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.smallTable > div {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}
.smallTable > div:not(:last-child) {
    border-bottom: #e2d3c3 1px solid;
}
.smallTable > div > div {
    margin: 0;
    padding: 8px 8px;
}
.smallTable > div > div.header {
    font-style: italic;
}
.smallTable > div > div:not(.header) {
    background: #f6f2ef;
}
.smallTable > div > div:not(:last-child) {
    border-right: #e0d0c1 1px solid;
}
.smallTable .itemFill {
    flex-grow: 1;
}

.smallTable > div:first-child > div:first-child {
    border-top-left-radius: 5px;
}
.smallTable > div:last-child > div:first-child {
    border-bottom-left-radius: 5px;
}
.smallTable > div:first-child > div:last-child {
    border-top-right-radius: 5px;
}
.smallTable > div:last-child > div:last-child {
    border-bottom-right-radius: 5px;
}

.clickable {
    cursor: pointer;
}

.inRow .headerContainer {
    margin-left: 20px;
    margin-right: 20px;
}
.inRow .headerContainer:first-child {
    margin-left: 0;
}
.inRow .headerContainer:last-child {
    margin-right: 0;
}
.headerContainer *:first-child {
    margin-bottom: 2px;
}
