
.deeplinks {
    width: 85%;
    margin: 0 auto 20px;
}

.deeplinks.deeplink-stats {
    width: 70%;
}

.deeplinks > ul {
    border: 1px solid #d9cfcb;
    border-radius: 3px;
    padding: 0;
    min-width: 550px;
}

.deeplinks > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
    margin: 0;
    word-wrap: break-word;
}
.deeplinks > ul > li > div {
    padding: 6px 0;
}
.deeplinks > ul > li:nth-child(odd) {
    background-color: #eceae6; /*#f1eee7;*/
}
.deeplinks > ul > li:nth-child(even) {
    background-color: #f1f0ef; /*#f0efea;*/
}

.deeplinks > ul > li.header {
    background-color: #efe4dc;
}
.deeplinks .header .stats {
    border-left: 1px solid #decabc;
}

.deeplinks .deeplink {
    width: 30%;
    text-align: left;
    border-left: 1px solid #e8dcd2;
    padding-left: 10px;
    font-size: 14px;
}
.deeplinks .targetLink {
    width: 46%;
    text-align: left;
    border-left: 1px solid #e7dad1;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
}
.deeplinks .statsTargetLink {
    width: 65%;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    font-size: 14px;
}
.deeplinks .stats2 {
    text-align: center;
    border-left: 1px solid #e2d4cc;
    padding: 8px 10px 2px;
}
.deeplinks .stats {
    width: 75px;
    text-align: center;
    border-left: 1px solid #e5dad1;
    padding: 8px 10px 2px;
}
.deeplinks .stats a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    color: #443b35;
}
.deeplinks .stats .zero {
    color: #b3a89e;
}
button.button-stats {
    background: url(/icons/stats.png) center center no-repeat;
    width: 25px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}

.deeplinks .section {
    align-items: flex-end;
}

/*nth-child(odd)*/

.deeplinks .info {
    padding: 0;
    width: 19%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.deeplinks .info .subinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px;
}

.deeplinks .info .name {
    font-size: 14px;
}
.deeplinks .info .date {
    font-size: 10px;
    color: #935b2b;
}
.deeplinks .info .id {
    font-size: 10px;
    color: #cdb19d;
    overflow: visible;
    width: 30px;
}
.deeplinks .info .id-only {
    color: #935b2b;
    font-size: 14px;
}

.deeplinks > ul.info, .deeplinks > * > ul.info {
    margin: 4px 12px;
}
.deeplinks > ul.info > li, .deeplinks > * > ul.info > li {
    padding: 0;
    margin: 0;
}

.deeplinks .info .rightIcons {
    margin: 3px 0 0;
    width: 30px;
}
.deeplinks .info .rightIcons > * {
    float: right;
}

.otherPostbackParam {
    font-size: 14px;
    padding: 2px;
    width: 70px;
}