
/* tiny button */

.fbCab {
    display: inline-block;
    position: relative;
    background: none;
    padding: 0;
    border: none;
    margin: 0 0;
}
.fbCab > .tinyItem {
    padding: 4px 3px 3px;
}

.fbCabButton {
    display: inline-block;
    position: relative;
    background: none;
    width: 12px;
    height: 7px;
    padding: 0;
    border: none;
}
.fbCabButton > .smallIcon {
    position: relative;
    vertical-align: baseline;
    opacity: 0.5;
    left: 3px;
    top: -1px;
}
.fbCabButton:hover > .smallIcon {
    opacity: 1;
}

.failedToRemove {
    background-color: #f7e6e6 !important;
}
.failedToAdd {
    background-color: #eee5db !important;
}
.deposit-funds h2 {
    margin: 5px 0 20px 20px;
}
.deposit-funds form ul > li.field > div:first-child {
    width: 190px;
    padding-left: 0px !important;
}
.deposit-funds form ul > li.field > div:last-child {
    width: 200px;
}
.deposit-funds form ul > li.submit {
    margin-top: 20px !important;
}

.deposit-funds .request {
    padding: 16px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 8px;
    width: 640px;
    text-align: left;
}
.deposit-funds .request > div > div {
    margin: 6px 0px;
}
.deposit-funds .request.pending, .deposit-funds .processing {
    background: #eae5e2;
    border: #cbb099 1px solid;
}

.deposit-funds .request.completed {
    background: #e5eae2;
    border: #adcb99 1px solid;
}

.deposit-funds .request.expired {
    color: #9a9a9a;
    background: #eeeeee;
    border: #cccccc 1px solid;
}
.deposit-funds .request .field > div:first-child {
    text-align: right;
    width: 35%;
}
.deposit-funds .request .field > div:last-child {
    width: 65%;
}

.deposit-funds .tokenFrame {
    width: auto;
    display: inline;
    margin: 2px 2px;
    padding: 2px 5px 2px 2px;
    font-family: 'Courier New', Arial, sans-serif;
    font-size: 16px;
}
.deposit-funds .tokenFrame button {
    position: relative;
    top: 4px;
}
.deposit-funds .pending .tokenFrame {
    color: #2b2e32;
}
.deposit-funds .expired .tokenFrame {
    color: #cccccc;
}