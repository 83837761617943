
.table-data-nb {
    width: 85%;
    margin: 0 auto 20px;
    padding: 0;
}

.table-data {
    width: 85%;
    margin: 0 auto 20px;
    border: 1px solid #ded1c6;
    border-radius: 3px;
    padding: 0;
    /*grid-template-columns: repeat(auto-fill, 33.33%);
    grid-template-rows: repeat(auto-fill, 100%);*/
}
.table-data.wide {
    width: 100%;
}
.table-data.wide90 {
    width: 90%;
}
.table-data.mid-width {
    width: 75%;
}
.table-data.thin {
    width: 50%;
}
.table-data.small .header {
    font-size: 12px;
}
.table-data.small .row > .col {
    font-size: 11px;
}

.table-data header, .table-data footer, .row {
    display: flex;  /* aligns all child elements (flex items) in a row */
}

.table-data .col {
    flex: 1;        /* distributes space on the line equally among items */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.table-data header, .table-data footer {
    background-color: #ece3df;
}
.table-data header > div {
    padding: 8px 0;
}
.table-data footer > div {
    padding: 8px 0;
}

.table-data .row {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
}
.table-data .row > div {
    padding: 6px 0;
}
.table-data .row:nth-child(odd) {
    background-color: #eceae6;
}
.table-data .row:nth-child(even) {
    background-color: #f1f0ef;
}

.table-data.compact .row {
    padding: 2px 0;
}


