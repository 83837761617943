
.pushlist {
    width: 75%;
    margin: 0 auto 20px;
}

.pushlist > ul {
    border: 1px solid #d9cfcb;
    border-radius: 3px;
    padding: 0;
    min-width: 350px;
}

.pushlist > ul > li {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    list-style-image: none;

    padding: 6px 0;
    margin: 0;
    word-wrap: break-word;
}
.pushlist > ul > li > div {
    padding: 6px 0;
}
.pushlist > ul > li:nth-child(odd) {
    background-color: #eceae6;
}
.pushlist > ul > li:nth-child(even) {
    background-color: #f1f0ef;
}

.pushlist > ul > li.header {
    background-color: #eee3db;
}
.pushlist .header .stats {
    border-left: 1px solid #e2ccbc;
}

.pushlist .message {
    width: 70%;
    text-align: left;
    border-left: 1px solid #e8dfd4;
    padding-left: 10px;
    padding-top: 10px;
}
.pushlist .message .title {
    font-size: 14px;
}
.pushlist .message .body {
    font-size: 12px;
}

.pushlist .stats {
    text-align: center;
    border-left: 1px solid #e7d9cf;
    padding: 12px 5px 12px 12px;
    white-space: nowrap;
    width: 85px;
}
.pushlist .actions {
    text-align: center;
    border-left: 1px solid #e2d3cc;
    padding: 12px 5px 7px 8px;
    width: 50px;
}
.pushlist .stats a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #3e342d;
}
.pushlist .stats .zero {
    color: #b1aca1;
}
button.button-stats {
    background: url(/icons/stats.png) center center no-repeat;
    width: 25px;
    height: 20px;
    padding: 0;
    border: none;
    outline: 0;
    position: relative;
    margin: 0 0 0 5px;
    top: -1px;
}
.pushRepeatIcon {
    background: url(../../img/push_repeat.png) center center no-repeat;
    width: 29px !important;
}

.pushlist .section {
    align-items: flex-end;
}

/*nth-child(odd)*/

.pushlist .info {
    padding: 0;
    width: 13%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.pushlist .info .subinfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2px;
}

.pushlist .info .name {

}
.pushlist .info .date {
    font-size: 10px;
    color: #935b2b;
}
.pushlist .info .id {
    font-size: 10px;
    color: #d4b7a3;
    overflow: visible;
    width: 0;
}
.pushlist .info .id-only {
    color: #935b2b;
    font-size: 14px;
}

.pushlist > ul.info, .pushlist > * > ul.info {
    margin: 4px 12px;
}
.pushlist > ul.info > li, .pushlist > * > ul.info > li {
    padding: 0;
    margin: 0;
}

.pushlist .info .rightIcons {
    margin: 3px 0 0;
    width: 0px;
}
.pushlist .info .rightIcons > * {
    float: right;
}

.newPush > ul > li.field, .newPush > * > ul > li.field {
    margin: 2px 10px;
}